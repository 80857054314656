export enum AppRoute {
  Account = 'account',
  ContentElements = 'content-elements',
  Courses = 'courses',
  Files = 'files',
  Forbidden = 'forbidden',
  Groups = 'groups',
  Home = 'home',
  Ideas = 'ideas',
  Languages = 'languages',
  Lessons = 'lessons',
  Login = 'login',
  Modules = 'modules',
  Users = 'users'
}
