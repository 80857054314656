import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { BaseService } from '../../base/services';
import { JadSelectionModel } from '../../functions/jad-selection-model';
import { __ } from '../../functions/object.functions';

@Injectable()
export class SelectionService<T> extends BaseService {

  /// -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  selections: JadSelectionModel<T>[] = [];

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  private _selectionChanges$: Subject<JadSelectionModel<T>> = new Subject<JadSelectionModel<T>>();

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC PRIVATE DEPENDENT INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  // tslint:disable-next-line:member-ordering
  public selectionChanges$: Observable<JadSelectionModel<T>> = this._selectionChanges$.asObservable();

  /// -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor() {
    super();
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  /**
   * Adds a selection model to the selections array
   *
   * @param selection The selection model being pushed into the selections array
   */
  add(selection: JadSelectionModel<T>): void {
    const foundSelectionIndex = (this.selections.findIndex(q => q.id === selection.id));

    if (foundSelectionIndex === -1) {
      this.selections.push(selection);
      return;
    }

    this.selections.splice(foundSelectionIndex, 1);
    this.selections.push(selection);
  }

  /**
   * Deselects a value or an array of values.
   * 
   * @param selectionId The id of the selection model
   * @param values The value or array of values to be deselected
   */
  deselect(selectionId: string, ...values: T[]): void {
    const index: number = this.selections.findIndex(q => q.id === selectionId);

    if (index === -1) return;

    this.selections[index].deselect(...values);

    this._selectionChanges$.next(this.selections[index]);
  }

  /**
   * Toggles a value between selected and deselected.
   * 
   * @param selectionId The id of the selection model
   * @param value The value to be toggled
   */
  toggle(selectionId: string, value: T): void {
    const index: number = this.selections.findIndex(q => q.id === selectionId);

    if (index === -1) return;

    this.selections[index].toggle(value);

    this._selectionChanges$.next(this.selections[index]);
  }

  /**
   * Select a value or an array of values.
   * 
   * @param selectionId The id of the selection model
   * @param values The value or array of values to be selected
   */
  select(selectionId: string, values: T[], selectValues: boolean = true): void {
    const index: number = this.selections.findIndex(q => q.id === selectionId);

    if (index === -1) return;

    if (selectValues === true) {
      this.selections[index].select(...values);
    }

    this._selectionChanges$.next(this.selections[index]);
  }

  /**
   * Determines whether a value is selected.
   * 
   * @param selectionId The id of the selection model
   * @param value The value to be checked
   */
  isSelected(selectionId: string, value: T): boolean {
    const index: number = this.selections.findIndex(q => q.id === selectionId);

    if (index === -1) return false;

    return this.selections[index].isSelected(value);
  }

}
