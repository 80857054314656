<div class="dialog-footer">

  <!-- DIVIDER -->
  <mat-divider class="dialog-footer__divider"></mat-divider>

  <!-- CONTENT -->
  <div class="dialog-footer__content flex gap-2">
    <ng-content></ng-content>
  </div>

</div>