import { Component } from '@angular/core';

/**
 * This component is responsible for the display of standardized dialog content
 *
 * @export
 */
@Component({
  selector: 'intello-dialog-content',
  templateUrl: './dialog-content.component.html',
  styleUrls: ['./dialog-content.component.scss']
})
export class DialogContentComponent { }
