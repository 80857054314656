<div class="dialog-header">

  <div class="dialog-header__container flex">

    <!-- TITLE -->
    <h3 class="dialog-header__title" [innerHTML]="title | safe:'html'">
    </h3> <!-- /dialog-header__title -->

    <!-- OPTIONAL ACTION BUTTONS -->
    <div *ngIf="items.length > 0" class="dialog-header__actions">
      <intello-generic-actions [items]="items"></intello-generic-actions>
    </div> <!-- /dialog-header__actions -->
    <div *ngIf="items.length === 0" class="dialog-header__actions ml-auto">
      <ng-content></ng-content>
    </div> <!-- /dialog-header__actions -->

    <!-- CLOSE ICON BUTTON -->
    <div *ngIf="closeIsVisible" class="pr-10 left-10">
      <mat-icon [matTooltip]="closeTooltipText" [matTooltipPosition]="closeTooltipPosition"
        class="medium close-navigation" [matTooltipDisabled]="closeTooltipDisabled" [svgIcon]="'close_navigation'"
        (click)="emitCloseClick.emit(true)"></mat-icon>
    </div> <!-- /dialog-header__close-icon-button -->

  </div>

  <!-- DIVIDER -->
  <mat-divider *ngIf="dividerIsVisible" class="dialog-header__divider dialog-header__divider--stretched"></mat-divider>

</div>